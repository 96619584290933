<template>
  <div class="m-5">
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mb-4">
        <el-breadcrumb-item :to="{ name: 'EditFaqHome' }"
          >Home</el-breadcrumb-item
        >
        <el-breadcrumb-item>關於我們</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="d-flex">
      <div>
        <h2>關於我們</h2>
      </div>
      <div class="pl-4" style="padding-top:6px;">
        <el-button
          type="success"
          size="small"
          icon="el-icon-plus"
          @click="dialogCreateVisible = true"
          >新增 Category</el-button
        >
      </div>
    </div>

    <div>
      <el-table :data="getLabelList.faq_labels" style="width: 100%">
        <el-table-column type="index" width="100"></el-table-column>
        <el-table-column label="Category" width="400">
          <template slot-scope="scope">
            <div
              style="cursor: pointer;"
              @click="handleRouterChange(scope.row)"
            >
              {{ helper.displayI18nText($i18n.locale, scope.row.label) }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="400">
          <template slot-scope="scope">
            <el-button
              @click="handleEditLabel(scope.row)"
              type="success"
              plain
              size="mini"
            >
              <i class="el-icon-edit"></i>
              更改名稱
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
            >
              <i class="el-icon-delete"></i>
              刪除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <br />
    </div>

    <!-- dialog create new category -->
    <el-dialog
      title="Create New Category"
      :visible.sync="dialogCreateVisible"
      width="60%"
      :close-on-click-modal="false"
      :before-close="handleCancelCreateLabel"
    >
      <el-form>
        <el-tabs type="border-card" v-model="defaultTabCreate">
          <el-tab-pane label="Category (EN)" name="1">
            <el-form-item label="Category (EN)">
              <el-input v-model="newLabel.label.en"></el-input>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="Category (TW)" name="2">
            <el-form-item label="Category (TW)">
              <el-input v-model="newLabel.label.tw"></el-input>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="Category (CN)" name="3">
            <el-form-item label="Category (CN)">
              <el-input v-model="newLabel.label.cn"></el-input>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancelCreateLabel">Cancel</el-button>
        <el-button type="primary" @click="submitCreateLabel">Submit</el-button>
      </span>
    </el-dialog>

    <!-- dialog edit current Category -->
    <el-dialog
      title="Edit Category"
      :visible.sync="dialogEditVisible"
      width="60%"
      :close-on-click-modal="false"
      :before-close="handleCancelEditLabel"
    >
      <el-form>
        <el-tabs type="border-card" v-model="defaultTabEdit">
          <el-tab-pane label="Category (EN)" name="1">
            <el-form-item label="Category (EN)">
              <el-input v-model="currentSelectedLabel.label.en"></el-input>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="Category (TW)" name="2">
            <el-form-item label="Category (TW)">
              <el-input v-model="currentSelectedLabel.label.tw"></el-input>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="Category (CN)" name="3">
            <el-form-item label="Category (CN)">
              <el-input v-model="currentSelectedLabel.label.cn"></el-input>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancelEditLabel">Cancel</el-button>
        <el-button type="primary" @click="submitUpdateLabel">Submit</el-button>
      </span>
    </el-dialog>

    <!-- dialog delete current Category -->
    <el-dialog
      title="Delete Category"
      :visible.sync="dialogDeleteVisible"
      width="60%"
      :close-on-click-modal="false"
      :before-close="handleCancelDeleteLabel"
    >
      <div>是否要刪除此 Category?</div>
      <div>en: {{ currentSelectedLabel.label.en }}</div>
      <div>tw: {{ currentSelectedLabel.label.tw }}</div>
      <div>cn: {{ currentSelectedLabel.label.cn }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancelDeleteLabel">Cancel</el-button>
        <el-button type="danger" @click="submitDeleteLabel">Delete</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapGetters } from "vuex";
/*eslint-enable */
import { helper } from "@ivy-way/material";
import _ from "lodash";

export default {
  data() {
    return {
      currentSelectedLabel: {},
      dialogCreateVisible: false,
      dialogEditVisible: false,
      dialogDeleteVisible: false,
      newLabel: {},
      defaultTabCreate: "1",
      defaultTabEdit: "1"
    };
  },
  computed: {
    ...mapGetters("faq", ["getLabelList"]),
    helper() {
      return helper;
    }
  },
  watch: {},
  created() {
    this.clearNewLabel();
    this.clearCurrentSelectedLabel();
    this.$store.dispatch("faq/clearTopicList");
  },
  async mounted() {
    await this.$store.dispatch("faq/getLabelList");
  },
  methods: {
    // 清空 newLabel
    clearNewLabel() {
      this.newLabel = {
        label: {
          en: "",
          tw: "",
          cn: ""
        }
      };
    },
    // 清空 currentSelectedLabel
    clearCurrentSelectedLabel() {
      this.currentSelectedLabel = {
        label: {
          en: "",
          tw: "",
          cn: ""
        }
      };
    },
    handleRouterChange(label) {
      this.$store.dispatch("faq/saveCurrentLabel", label);
      this.$router.push({
        name: "EditFaqCategory"
      });
    },
    goToRoute(name) {
      this.$router.push({
        name: name
      });
    },

    // create new label
    // 點選 "取消"
    handleCancelCreateLabel() {
      this.dialogCreateVisible = false;
      this.clearNewLabel();
      this.defaultTabCreate = "1";
    },
    // 點選 "送出"
    async submitCreateLabel() {
      this.dialogCreateVisible = false;
      await this.$store.dispatch("faq/createLabel", this.newLabel);
      this.clearNewLabel();
      await this.$store.dispatch("faq/getLabelList");
      this.defaultTabCreate = "1";
    },

    // edit label
    // 在 table 點選某項 label 時，assign 此 label 至 currentSelectedLabel
    handleEditLabel(label) {
      this.currentSelectedLabel = _.cloneDeep(label);
      this.dialogEditVisible = true;
    },
    // 點選 "取消"
    handleCancelEditLabel() {
      this.dialogEditVisible = false;
      this.clearCurrentSelectedLabel();
      this.defaultTabEdit = "1";
    },
    // 點選 "送出"
    async submitUpdateLabel() {
      await this.$store.dispatch("faq/updateLabel", this.currentSelectedLabel);
      this.clearCurrentSelectedLabel();
      this.dialogEditVisible = false;
      await this.$store.dispatch("faq/getLabelList");
      this.defaultTabEdit = "1";
    },
    // delete label
    handleDelete(label) {
      this.currentSelectedLabel = _.cloneDeep(label);
      this.dialogDeleteVisible = true;
    },
    handleCancelDeleteLabel() {
      this.dialogDeleteVisible = false;
      this.clearCurrentSelectedLabel();
    },
    async submitDeleteLabel() {
      await this.$store.dispatch(
        "faq/deleteLabel",
        this.currentSelectedLabel.id
      );
      this.clearCurrentSelectedLabel();
      this.dialogDeleteVisible = false;
      await this.$store.dispatch("faq/getLabelList");
    }
  }
};
</script>

<style lang="scss" scoped>
.el-button {
  outline: none;
}
</style>
